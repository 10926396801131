<template>
  <div>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-title>Sync Balance</v-list-item-title>
        </v-list-item>
      </template>
      <v-card v-if="dialog" class="pa-4" color="primary" dark>
        <v-card-text>
          <span class="white--text">Re-syncing Vendor Balance</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vendorService from "@/modules/Inventory/Vendor/service.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("vendor", ["getVendor"]),
  },
  watch: {
    dialog(val) {
      if (val) {
        this.syncBalance(this.getVendor.id);
      }
    },
  },
  methods: {
    ...mapActions("vendor", ["setVendor"]),
    syncBalance(id) {
      return vendorService.syncBalance(id).then((result) => {
        // console.log("result", result);
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "success",
            title: "Balance Updated!",
          });
        // this.$swal({
        //   title: "Balance Updated!",
        //   timer: 1000,
        //   timerProgressBar: true,
        //   icon: "success",
        // });
        this.setVendor(id);
        this.dialog = false;
      });
    },
  },
};
</script>
